import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const UpgradeBtnWrapper = styled('div')(({ theme }) => ({
  background: theme.colors.white.primary,
  bottom: 0,
  boxShadow: `0 -0.1rem 0.8rem 0 ${theme.colors.gray['300']}`,
  display: 'flex',
  justifyContent: 'space-around',
  left: 0,
  margin: '0 auto',
  minHeight: '12rem',
  minWidth: '100%',
  position: 'fixed',
  right: 0,
  verticalAlign: 'top',
  zIndex: 115 /* sits on top of mini player */,
  br: {
    display: 'none',
    [mediaQueryBuilder(theme.mediaQueries.max.width['640'])]: {
      display: 'block',
    },
  },
  'button:disabled + h3': {
    '> span': {
      opacity: 0.2,
    },
  },
  paddingRight: '25%',
  paddingLeft: '25%',
  [mediaQueryBuilder(theme.mediaQueries.max.width['1160'])]: {
    paddingRight: '10%',
    paddingLeft: '10%',
  },
  [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
    paddingRight: '0%',
    paddingLeft: '0%',
  },
}));

export default UpgradeBtnWrapper;
