import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const SubscriptionBenefits = styled('table')(({ theme }) => ({
  margin: '0 auto',
  position: 'relative',
  width: '80%',
  zIndex: 3,

  [mediaQueryBuilder(theme.mediaQueries.max.width['1160'])]: {
    width: '100%',
  },

  [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
    margin: '0 0 6rem 0',
    top: '6rem',
    width: '100%',
  },

  [mediaQueryBuilder(theme.mediaQueries.max.width['640'])]: {
    margin: '0 0 8rem 0',
  },

  'thead > tr': {
    backgroundColor: `${theme.colors.transparent.primary} !important`,
  },

  'th:nth-of-type(1)': {
    padding: '1.5rem 0rem 1.5rem 1.5rem',
  },

  'th:nth-of-type(2)': {
    color: theme.colors.blue.primary,
  },
  td: {
    color: theme.colors.gray.medium,
    fontSize: '3rem',
  },

  'td:nth-of-type(1)': {
    fontSize: '1.6rem',
    padding: '2rem 0rem 2rem 2rem',

    [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
      fontSize: '1.4rem',
    },

    [mediaQueryBuilder(theme.mediaQueries.max.width['640'])]: {
      padding: `${theme.dimensions.gutter} 0 ${theme.dimensions.gutter} ${theme.dimensions.gutter}`,
    },
  },

  'td:nth-of-type(2)': {
    color: theme.colors.blue.primary,
    textAlign: 'center',
  },

  'td:nth-of-type(3), th:nth-of-type(3)': {
    color: theme.colors.purple.primary,
    textAlign: 'center',
  },

  'td:nth-of-type(4),th:nth-of-type(4)': {
    backgroundColor: theme.colors.orange['100'],
    color: theme.colors.orange.primary,
    padding: `0 calc(${theme.dimensions.gutter} / 2)`,
    textAlign: 'center',

    [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
      paddingRight: theme.dimensions.gutter,
    },
  },

  'tr:nth-of-type(odd)': {
    backgroundColor: theme.colors.gray.secondary,
  },

  'tr:nth-of-type(even)': {
    backgroundColor: theme.colors.white.primary,
  },
}));

export default SubscriptionBenefits;
