import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const ColLeft = styled.th(({ theme }) => ({
  padding: '1.5rem 0rem 1.5rem 1.5rem',
  textAlign: 'left',
  width: '70%',

  [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
    fontSize: '1.4rem',
  },

  [mediaQueryBuilder(theme.mediaQueries.max.width['640'])]: {
    width: '66%',
  },
}));

export default ColLeft;
