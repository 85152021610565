import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const ColRight = styled.th(({ theme }) => ({
  position: 'relative',
  textAlign: 'center',
  width: '12%',

  [mediaQueryBuilder(theme.mediaQueries.max.width['1160'])]: {
    width: '15%',
  },

  [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
    fontSize: '1.4rem',
  },

  [mediaQueryBuilder(theme.mediaQueries.max.width['640'])]: {
    fontSize: '1.2rem',
    width: '19%',
  },
}));

export default ColRight;
