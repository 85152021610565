import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const Warning = styled('div')(({ theme }) => ({
  color: theme.colors.gray.medium,
  fontSize: '1.2rem',
  margin: '0.5rem auto 0',
  width: '80%',
  height: '5rem',
  '> span': {
    float: 'none',
  },

  [mediaQueryBuilder(theme.mediaQueries.max.width['1160'])]: {
    width: '100%',
  },

  [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
    margin: '7rem 0 0',
    textAlign: 'right',
    width: '100%',

    '> span': {
      float: 'none',
      marginRight: '1.5rem',
    },
  },

  [mediaQueryBuilder(theme.mediaQueries.max.width['640'])]: {
    margin: '-1rem 0 0',
  },
}));

export default Warning;
