import * as UPSELL_FROM from 'modules/Analytics/constants/upsellFrom';
import analytics from 'modules/Analytics';
import ButtonWrapper from 'components/Recurly/primitives/ButtonWrapper';
import ColLeft from './primitives/ColLeft';
import ColRight from './primitives/ColRight';
import FilledButton from 'primitives/Buttons/FilledButton';
import H3 from 'primitives/Typography/Headings/H3';
import HeroContainer from 'components/Recurly/primitives/HeroContainer';
import HeroStyles from 'components/Recurly/primitives/Hero';
import HeroText from 'components/Recurly/primitives/HeroText';
import Logo from 'components/Recurly/primitives/Logo';
import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import NavLink from 'components/NavLink';
import paths from 'router/Routes/paths';
import qs from 'qs';
import RecurlyHOC from 'components/Recurly/RecurlyHOC';
import SubscriptionBenefits from 'components/Recurly/primitives/SubscriptionBenefits';
import TermsLink from 'components/Recurly/primitives/TermsLink';
import UpgradeBtnWrapper from 'components/Recurly/primitives/UpgradeBtnWrapper';
import UPSELL, { getUpsellById } from 'constants/upsellTracking';
import Warning from 'components/Recurly/primitives/Warning';
import { Component, ReactNode } from 'react';
import { getUpsellFromQueryParam } from 'modules/Analytics/helpers/upsell';
import { isEmpty } from 'lodash-es';
import { Navigate } from 'state/Routing/types';
import {
  RECURLY_SUBSCRIPTION_ID,
  SUBSCRIPTION_TYPE,
} from 'constants/subscriptionConstants';
import { RecurlySkus } from 'state/SiteData/types';
import type { Theme } from 'styles/themes/default';

type Props = {
  fromLearnMore: boolean;
  getRecurlySkus: () => void;
  hideHero: (isHeroHidden: boolean) => void;
  isTrial: boolean;
  isTrialEligible: boolean;
  navigate: Navigate;
  openSignupModal: (trackingContext?: Record<string, string>) => void;
  recurly?: string;
  recurlySkus: RecurlySkus;
  search: string;
  subInfoLoaded: boolean;
  termsLink: string;
  upsellFrom: string;
  userIsAutoRenewing: boolean;
  userSubSource: string;
  userSubType: string;
  isAnonymous: boolean;
};

class Upgrade extends Component<Props> {
  continueUpgrade = false;

  componentDidMount() {
    const { getRecurlySkus, recurlySkus } = this.props;
    if (
      this.props.subInfoLoaded &&
      this.props.userSubType === SUBSCRIPTION_TYPE.NONE
    ) {
      this.props.navigate({ path: '/404' });
    } else if (this.props.isAnonymous) {
      this.props.openSignupModal();
    }
    // Fetch the recurly skus
    if (isEmpty(recurlySkus)) {
      getRecurlySkus();
    }

    // check to see if we got here from a deeplink or by typing url directly if we did,
    // track upsell for this
    if (!this.props.fromLearnMore) {
      const isRecognizedUpsellFrom = !!getUpsellById(this.props.upsellFrom);
      if (
        !this.props.upsellFrom ||
        this.props.upsellFrom === UPSELL.DEEPLINK.id.toString(10) ||
        !isRecognizedUpsellFrom
      ) {
        const upsellFrom = { ...UPSELL.DEEPLINK };
        upsellFrom.name =
          document.referrer ? `${document.referrer}/Deeplink` : 'Deeplink';
        upsellFrom.pageName =
          document.referrer ?
            `${upsellFrom.pageName}:${document.referrer}`
          : upsellFrom.pageName;
      }
    }

    const queryParam = qs.parse(this.props.search, { ignoreQueryPrefix: true });
    const [upsellFrom, upsellType] = getUpsellFromQueryParam(
      queryParam.upsellFrom,
    );

    if (upsellFrom) {
      analytics.trackUpsellOpen!({
        destination: UPSELL_FROM.NEW_SCREEN,
        promotionSubscriptionTier: UPSELL_FROM.GENERAL,
        upsellFrom,
        upsellType,
        vendor: UPSELL_FROM.NATIVE,
      });
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    // this is intended to select AU/NZ anonymous users - apparently they have userSubType of NONE.
    if (
      nextProps.subInfoLoaded &&
      nextProps.userSubType === SUBSCRIPTION_TYPE.NONE
    ) {
      this.props.navigate({ path: '/404' });
    } else if (this.props.isAnonymous) {
      this.props.openSignupModal();
    }
  }

  componentWillUnmount() {
    this.props.hideHero(false);

    if (!this.continueUpgrade) {
      analytics.trackUpsellExit!({
        destination: UPSELL_FROM.NEW_SCREEN,
        exitType: UPSELL_FROM.DISMISS,
      });
    }
  }

  handleTermsClick = () => {
    window.location.href = this.props.termsLink;
  };

  handleUpgrade = (currentSkuCode: string) => {
    if (this.props.isAnonymous) {
      this.props.openSignupModal();
      return;
    }

    const { recurlySkus } = this.props;
    this.continueUpgrade = true;
    const subId = recurlySkus[currentSkuCode].code;
    let location = `${paths.recurly.subscribe}?subscriptionId=${subId}`;
    const windowParams = qs.parse(window.location.search);
    const upsell = windowParams.upsellFrom || windowParams['?upsellFrom'];
    if (upsell) {
      location = `${location}&upsellFrom=${upsell}`;
    }
    window.location.href = location;
  };

  buttonBuilder = () => {
    const {
      userIsAutoRenewing,
      userSubType,
      isTrial,
      isTrialEligible,
      recurlySkus,
    } = this.props;
    const plusButton =
      !userIsAutoRenewing || userSubType === SUBSCRIPTION_TYPE.FREE;
    const premButton =
      !userIsAutoRenewing ||
      userSubType === SUBSCRIPTION_TYPE.FREE ||
      userSubType === SUBSCRIPTION_TYPE.PLUS;

    const showTrial = isTrial ? false : isTrialEligible;
    const trialBtnCopy = (
      <div>
        Start Free <br /> 30 Day Trial
      </div>
    );

    /**
     * These are the only plans available to choose from on this page, if we want to add more add it to this list
     * you would also need to add sku meta to plan you want show so that the lookup wont fail
     * */
    const whiteListSkus = [
      RECURLY_SUBSCRIPTION_ID.IHEART_US_PLUS,
      RECURLY_SUBSCRIPTION_ID.IHEART_US_PREMIUM,
    ];

    const skuMeta: {
      [key: string]: {
        buttonCopy: string;
        dataText: string;
        disabled: boolean;
        index: number;
        styeType: 'cta' | 'cta2';
        text: string;
        wrapperProp: boolean;
      };
    } = {
      [RECURLY_SUBSCRIPTION_ID.IHEART_US_PLUS]: {
        buttonCopy:
          userSubType === SUBSCRIPTION_TYPE.PLUS ?
            'You Currently Have Plus'
          : (recurlySkus?.[RECURLY_SUBSCRIPTION_ID.IHEART_US_PLUS]?.name ??
            'iHeart Radio Plus'),
        dataText: '',
        disabled: !plusButton,
        index: 0,
        styeType: 'cta',
        text: 'iHeart Plus',
        wrapperProp: true,
      },
      [RECURLY_SUBSCRIPTION_ID.IHEART_US_PREMIUM]: {
        buttonCopy:
          userSubType === SUBSCRIPTION_TYPE.PREMIUM ?
            'You Currently Have All Access'
          : (recurlySkus?.[RECURLY_SUBSCRIPTION_ID.IHEART_US_PREMIUM]?.name ??
            'iHeart Radio All Access'),
        dataText: '-2',
        disabled: !premButton,
        index: 1,
        styeType: 'cta2',
        text: 'Subscribe to All Access',
        wrapperProp: false,
      },
    };

    const skuButtons: [ReactNode?] = [];

    if (!isEmpty(recurlySkus)) {
      const activeSkus = Object.keys(recurlySkus).filter(
        sku => whiteListSkus.indexOf(sku) > -1,
      );
      activeSkus.forEach(sku => {
        const currentSku = recurlySkus[sku];
        skuButtons[skuMeta[sku].index] = (
          <ButtonWrapper
            data-test={`button-wrapper-container${skuMeta[sku].dataText}`}
            key={skuMeta[sku].text}
          >
            <FilledButton
              data-test={`upgrade-button-${currentSku.code}`}
              data-test-id={`upgrade-${currentSku.code}-button`}
              disabled={skuMeta[sku].disabled}
              display="block"
              marginLeft="auto"
              marginRight="auto"
              onClick={() => this.handleUpgrade(sku)}
              styleType={skuMeta[sku].styeType}
            >
              {showTrial ? trialBtnCopy : skuMeta[sku].buttonCopy}
            </FilledButton>
            <H3
              css={(theme: Theme) => ({
                color: theme.colors.gray['400'],
                marginTop: '1rem',
                textAlign: 'center',
                [mediaQueryBuilder(theme.mediaQueries.max.width['640'])]: {
                  fontSize: theme.fonts.size['14'],
                },
              })}
            >
              {skuMeta[sku].text}
            </H3>
          </ButtonWrapper>
        );
      });
    }

    return skuButtons;
  };

  render() {
    const { navigate, recurly } = this.props;
    // There are a couple of elements here that have a data-test-id
    // attribute, rather than the standard data-test attribute. This
    // is because, for some unaccountable reason, data-test was getting
    // truncated to datatest, but only when accessed through the Cypress
    // browser. Rather than debug Cypress and React at the same time,
    // we settled on using a different attribute.
    if (__CLIENT__ && !recurly) {
      return navigate({ path: '/404/' });
    }
    return (
      <RecurlyHOC>
        <HeroContainer>
          <HeroStyles>
            <Logo />
            <HeroText>
              Take your iHeart listening experience to a new level
            </HeroText>
          </HeroStyles>
        </HeroContainer>
        <SubscriptionBenefits>
          <thead>
            <tr>
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <ColLeft />
              <ColRight>Plus</ColRight>
              <ColRight>All Access</ColRight>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Commercial free radio</td>
              <td>&bull;</td>
              <td>&bull;</td>
            </tr>
            <tr>
              <td>No skip limit</td>
              <td>&bull;</td>
              <td>&bull;</td>
            </tr>
            <tr>
              <td>Save songs from radio to your playlist</td>
              <td>&bull;</td>
              <td>&bull;</td>
            </tr>
            <tr>
              <td>Play any song on demand</td>
              <td />
              <td>&bull;</td>
            </tr>
            <tr>
              <td>Instantly replay songs from the radio</td>
              <td />
              <td>&bull;</td>
            </tr>
            <tr>
              <td>Listen offline*</td>
              <td />
              <td>&bull;</td>
            </tr>
            <tr>
              <td>Unlimited access to millions of songs</td>
              <td />
              <td>&bull;</td>
            </tr>
            <tr>
              <td>Create unlimited playlists</td>
              <td />
              <td>&bull;</td>
            </tr>
          </tbody>
        </SubscriptionBenefits>
        <Warning>
          <span>
            *This feature is only available on the iHeart iOS and Android mobile
            apps
          </span>
        </Warning>
        <TermsLink>
          <NavLink onClick={this.handleTermsClick} title="iHeart">
            {' '}
            Terms & Conditions
          </NavLink>
        </TermsLink>
        <UpgradeBtnWrapper>{this.buttonBuilder()}</UpgradeBtnWrapper>
      </RecurlyHOC>
    );
  }
}

export default Upgrade;
