import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const TermsLink = styled('div')(({ theme }) => ({
  bottom: 0,
  left: 0,
  position: 'absolute',
  right: 0,
  textAlign: 'center',
  zIndex: 3,
  [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
    bottom: 0,
  },
}));

export default TermsLink;
